import { Scope } from '../shared';
import { billingAccountLinkPermissions } from './billing-payments';

export const account = {
  id: 'accountStandalone',
  name: _TL_('Account'),
  children: [
    {
      id: 'linkAccounts',
      name: _TL_('Link Accounts'),
      route: 'Agency/SendInvite',
      scope: [Scope.Customer],
    },
    {
      id: 'pendingBillingChange',
      name: _TL_('Pending Billing Change'),
      route: 'Account/PendingBillingChange',
      accountLinkPermissions: billingAccountLinkPermissions,
    },
    {
      id: 'switchPaymentSetting',
      name: _TL_('Change How You Pay'),
      route: 'Account/SwitchPaymentSetting',
      accountLinkPermissions: billingAccountLinkPermissions,
    },
    {
      id: 'takeOverBilling',
      name: _TL_('Change Who Pays'),
      route: 'Account/TakeOverBilling',
      accountLinkPermissions: billingAccountLinkPermissions,
    },
  ],
};

const coupon = {
  id: 'coupon',
  name: _TL_('Coupon'),
  route: 'Billing/CouponDetails',
  searchable: true,
  accountLinkPermissions: billingAccountLinkPermissions,
};

export const couponDetails = {
  ...coupon,
  scope: [Scope.Account],
};

export const couponDetailsCustomer = {
  ...coupon,
  scope: [Scope.Customer],
  checkPermission: permissions => permissions.dynamic.CustomerLevelCouponDetails,
};

export const billing = {
  id: 'billingStandalone',
  name: _TL_('Billing'),
  children: [
    couponDetails,
    couponDetailsCustomer,
    {
      id: 'payNow',
      name: _TL_('Pay Now'),
      route: 'Billing/PayNow',
      accountLinkPermissions: billingAccountLinkPermissions,
    },
  ],
};

export const insertionOrder = {
  id: 'insertionOrderStandalone',
  name: _TL_('Insertion orders'),
  accountLinkPermissions: billingAccountLinkPermissions,
  children: [
    {
      id: 'ioImport',
      name: _TL_('Bulk import insertions orders'),
      route: 'InsertionOrder/BulkImport',
      scope: [Scope.Customer],
    },
    {
      id: 'ioImportHistory',
      name: _TL_('Import history insertions orders'),
      route: 'InsertionOrder/IOImportHistoryList',
      scope: [Scope.Customer],
    },
    {
      id: 'ioPreApprove',
      name: _TL_('Pre-approve insertions orders'),
      route: 'InsertionOrder/PreApprove',
      scope: [Scope.Customer],
    },
    {
      id: 'ioViewHistory',
      name: _TL_('View history insertions orders'),
      route: 'InsertionOrder/ViewChangeHistory',
    },
  ],
};

export const payment = {
  id: 'paymentStandalone',
  name: _TL_('Payment'),
  accountLinkPermissions: billingAccountLinkPermissions,
  children: [
    {
      id: 'addFunds',
      name: _TL_('Add funds'),
      route: 'Payment/Prepay',
    },
    {
      id: 'autoRecharge',
      name: _TL_('Auto recharge'),
      route: 'Payment/AutoPayment',
    },
    {
      id: 'paymentSlipRegisteredBoleto',
      name: _TL_('Payment Slip Registered Boleto'),
      route: 'Boleto/PaymentSlipRegisteredBoleto',
    },
    {
      id: 'sapOnboarding',
      name: _TL_('SAP Onboarding'),
      route: 'SAPOnboarding',
      scope: [Scope.Customer],
    },
    {
      id: 'creditHold',
      name: _TL_('Credit hold'),
      children: [
        {
          id: 'creditHoldDashboard',
          name: _TL_('Credit hold dashboard'),
          route: 'CreditHold/Dashboard',
          scope: [Scope.Customer],
          checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
        },
        {
          id: 'creditHoldResolveOptions',
          name: _TL_('Credit hold resolve options'),
          route: 'CreditHold/ResolveOptions',
          scope: [Scope.Customer],
          checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
        },
        {
          id: 'creditHoldPaymentDetails',
          name: _TL_('Credit hold payment details'),
          route: 'CreditHold/PaymentDetails',
          scope: [Scope.Customer],
          checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
        },
        {
          id: 'creditHoldReviewBalance',
          name: _TL_('Credit hold review balance'),
          route: 'CreditHold/ReviewBalance',
          scope: [Scope.Customer],
          checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
        },
        {
          id: 'creditHoldSummary',
          name: _TL_('Credit hold summary'),
          route: 'CreditHold/Summary',
          scope: [Scope.Customer],
          checkPermission: permissions => permissions.dynamic.WebpackifyPhase2Customer,
        },
      ],
    },
  ],
};

export const rebill = {
  id: 'rebillStandalone',
  name: _TL_('Rebill'),
  scope: [Scope.Account],
  children: [
    {
      id: 'rebill',
      name: _TL_('Rebill'),
      route: 'Rebill',
      checkPermission: permissions => !!permissions.IsInternalUser,
      scope: [Scope.Account],
    },
    {
      id: 'rebillDetails',
      name: _TL_('Rebill details'),
      route: 'Rebill/Details',
      checkPermission: permissions => !!permissions.IsInternalUser,
      scope: [Scope.Account],
    },
  ],
};

export const xandrBankAssociation = {
  id: 'xandrBankAssociation',
  name: _TL_('Xandr seller side bank association'),
  route: 'Xandr/BankAssociation',
  scope: [Scope.Customer],
  searchable: false,
  checkPermission: permissions => !permissions.IsInternalUser,
};

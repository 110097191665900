import { available, scope } from './common';

export const assetsExtensions = {
  id: 'reporting-category-extensions',
  name: _TL_('Assets and Extensions'),
  scope,
  available,
  children: [
    {
      id: 'reporting-extensionkeywordreport',
      name: _TL_('Ad extension by keyword'),
      title: _TL_('See how your extensions perform for your keywords.'),
      fixedRoute: 'reporting/create/extensionkeywordreport',
      route: 'reporting/create/extensionkeywordreport',
      searchAlias: _TL_('Ad extension by keyword report'),
      scope,
      available,
      apiKey: 'AdExtensionByKeywordReport',
      description: _TL_('Review the performance of various click types for each keyword based on the ad extensions shown.'),
    }, {
      id: 'reporting-extensionadreport',
      name: _TL_('Ad extension by ad'),
      title: _TL_('See how your extensions perform for your ads.'),
      fixedRoute: 'reporting/create/extensionadreport',
      route: 'reporting/create/extensionadreport',
      searchAlias: _TL_('Ad extension by ad report'),
      scope,
      available,
      apiKey: 'AdExtensionByAdReport',
      description: _TL_('Review the performance of your ads’ ad extensions.'),
    }, {
      id: 'reporting-adextensiondetailreport',
      name: _TL_('Ad extension details'),
      title: _TL_('See how engaging your ad extension items are.'),
      fixedRoute: 'reporting/create/adextensiondetailreport',
      route: 'reporting/create/adextensiondetailreport',
      searchAlias: _TL_('Ad extension details report'),
      scope,
      available,
      apiKey: 'AdExtensionDetailReport',
      description: _TL_('Evaluate the performance of each ad extension item, including the links within a sitelink extension.'),
    }, {
      title: _TL_('See duration and phone spend details for each forwarded call.'),
      id: 'reporting-callforwarddetailreport',
      // Duplicate
      name: _TL_('Call forwarding detail'),
      fixedRoute: 'reporting/create/callforwarddetailreport',
      route: 'reporting/create/callforwarddetailreport',
      searchAlias: _TL_('Call forwarding detail report'),
      scope,
      available,
      apiKey: 'CallDetailReport',
      description: _TL_('Review which accounts, campaigns, or ad groups are performing with call extensions.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.CallMetering,
    }, {
      id: 'reporting-assetperformancereport',
      name: _TL_('Asset performance'),
      title: _TL_('View the performance of your search and Performance Max assets for each account, campaign, or ad group.'),
      fixedRoute: 'reporting/create/assetperformancereport',
      route: 'reporting/create/assetperformancereport',
      searchAlias: _TL_('Asset performance report'),
      scope,
      available,
      apiKey: 'AssetPerformanceReport',
      description: _TL_('Review the performance of your Search, Audience and Performance Max assets for each account, campaign, or ad group.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultAssetReport,
    }, {
      id: 'reporting-addynamictextreport',
      name: _TL_('Dynamic text ad'),
      title: _TL_('See which ad text strings are doing well.'),
      fixedRoute: 'reporting/create/addynamictextreport',
      route: 'reporting/create/addynamictextreport',
      searchAlias: _TL_('Ad dynamic text report'),
      scope,
      available,
      apiKey: 'AdDynamicTextPerformanceReport',
      description: _TL_('Identify which dynamic text strings perform well and which may need adjustments.'),
    },
  ],
};

import { available, scope } from './common';

export const competitiveVisibility = {
  id: 'reporting-category-competitive-visibility',
  name: _TL_('Competitive Visibility'),
  scope,
  available,
  children: [
    {
      id: 'reporting-shareofvoicereport',
      name: _TL_('Share of voice'),
      title: _TL_('See estimated impressions you might be losing.'),
      fixedRoute: 'reporting/create/shareofvoicereport',
      route: 'reporting/create/shareofvoicereport',
      searchAlias: _TL_('Share of voice report'),
      scope,
      available,
      apiKey: 'ShareOfVoiceReport',
      description: _TL_('Check the percentage of your impression share (%) in the marketplace to determine if you are missing opportunities based on your current campaign settings. This information can help you make adjustments to improve your impression share.'),
    },
  ],
};

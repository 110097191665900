import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const verticalAds = {
  id: 'reporting-category-vertical-ads',
  name: _TL_('Ad Type: Vertical Ads'),
  scope,
  available,
  children: [
    {
      id: 'reporting-feeditemreport',
      name: _TL_('Feed item'),
      title: _TL_('See performance on feed item level.'),
      fixedRoute: 'reporting/create/feeditemreport',
      route: 'reporting/create/feeditemreport',
      searchAlias: _TL_('Feed item report'),
      scope,
      available,
      apiKey: 'FeedItemPerformanceReport',
      description: _TL_('Get detailed performance statistics for each individual item from the Vertical ads inventory including the deleted and inactive Dynamic Data Feed items.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.ReportingUpliftFeedItemReport,
    },
  ],
};

import { available, scope } from './common';

export const productAds = {
  id: 'reporting-category-productads',
  name: _TL_('Product ads'),
  title: _TL_('Product ad reports give you a closer look at how your Bing Shopping Campaigns are performing based on product partition and product offer data.'),
  scope,
  available,
  children: [
    {
      id: 'reporting-productpartitionreport',
      name: _TL_('Product partition'),
      title: _TL_('See the performance of your Product Groups in Shopping Campaigns '),
      fixedRoute: 'reporting/create/productpartitionreport',
      route: 'reporting/create/productpartitionreport',
      searchAlias: _TL_('Product partition report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductPartitionPerformanceReport',
      description: _TL_('Review product group performance data to optimize your Shopping campaigns.'),
    }, {
      id: 'reporting-productpartitionunitreport',
      name: _TL_('Product partition unit'),
      title: _TL_('See product partition unit data of your Product Groups in Shopping Campaigns'),
      fixedRoute: 'reporting/create/productpartitionunitreport',
      route: 'reporting/create/productpartitionunitreport',
      searchAlias: _TL_('Product partition unit report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductPartitionUnitPerformanceReport',
      description: _TL_('Review the product partition unit data of your Product groups from your Shopping campaigns.'),
    }, {
      id: 'reporting-productdimensionreport',
      name: _TL_('Product dimension'),
      title: _TL_('See the performance of your Products in Shopping Campaigns '),
      fixedRoute: 'reporting/create/productdimensionreport',
      route: 'reporting/create/productdimensionreport',
      searchAlias: _TL_('Product dimension report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductDimensionPerformanceReport',
      description: _TL_('Review which of your products are getting the most clicks.'),
    }, {
      id: 'reporting-productsearchtermreport',
      name: _TL_('Product search term'),
      title: _TL_('See which search terms are causing your ads to display.'),
      fixedRoute: 'reporting/create/productsearchtermreport',
      route: 'reporting/create/productsearchtermreport',
      searchAlias: _TL_('Product search term report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductSearchQueryPerformanceReport',
      description: _TL_('Discover what your audience searches for when your Product ads appear. Use this data to update your keyword and negative keyword lists effectively.'),
    }, {
      id: 'reporting-productmatchcountreport',
      name: _TL_('Product match count'),
      title: _TL_('See how products are distributed acrocss given product tree.'),
      fixedRoute: 'reporting/create/productmatchcountreport',
      route: 'reporting/create/productmatchcountreport',
      searchAlias: _TL_('Product match count report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProductMatchCountReport',
      description: _TL_('Ensure comprehensive bidding coverage across the Microsoft Shopping campaigns inventory.'),
    }, {
      id: 'reporting-productnegativekeywordreport',
      name: _TL_('Product negative keyword conflicts'),
      title: _TL_('See a set of negative keyword pairs that are blocking your products from participating in the auction.'),
      fixedRoute: 'reporting/create/productnegativekeywordreport',
      route: 'reporting/create/productnegativekeywordreport',
      searchAlias: _TL_('Product negative keyword conflicts report'),
      scope,
      available,
      apiKey: 'ProductNegativeKeywordConflictReport',
      description: _TL_('Identify which products and negative keywords are in conflict, and determine which negative keywords to delete.'),
    },
  ],
};

import { available, scope } from './common';

export const advancedInsights = {
  id: 'reporting-category-advancedinsights',
  name: _TL_('Advanced insights'),
  title: _TL_('Find advanced insights into how your advertisers are performing'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    !!permissions.IsInternalUser,
  children: [
    {
      id: 'reporting-sixweekaccountactivityreport',
      name: _TL_('Account - 6 weeks'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reporting/create/sixweekaccountactivityreport',
      route: 'reporting/create/sixweekaccountactivityreport',
      searchAlias: _TL_('Account - 6 weeks report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-SixWeeks',
      description: _TL_('Review your Microsoft Advertising account performance and trends over a six-week period.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reporting-twomonthaccountactivityreport',
      name: _TL_('Account - 2 months (MoM)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reporting/create/twomonthaccountactivityreport',
      route: 'reporting/create/twomonthaccountactivityreport',
      searchAlias: _TL_('Account - 2 months (MoM) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoMonths',
      description: _TL_('View the month-over-month performance for individual accounts'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reporting-twoyearaccountactivityreport',
      name: _TL_('Account - 2 years (YoY)'),
      title: _TL_('See how individual accounts are performing with excel charts and graphs'),
      fixedRoute: 'reporting/create/twoyearaccountactivityreport',
      route: 'reporting/create/twoyearaccountactivityreport',
      searchAlias: _TL_('Account - 2 years (YoY) report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-TwoYears',
      description: _TL_('View the year-over-year performance for individual accounts'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    }, {
      id: 'reporting-advancedinsightsshareofvoicereport',
      name: _TL_('Account - Share of voice'),
      title: _TL_('See estimated impressions you might be losing.'),
      fixedRoute: 'reporting/create/advancedinsightsshareofvoicereport',
      route: 'reporting/create/advancedinsightsshareofvoicereport',
      searchAlias: _TL_('Account - Share of voice report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AccountPerformanceReport-ShareOfVoice',
      description: _TL_('Review the impression share (%) of successful bids for each keyword and identify potential opportunities.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.IsInternalUser,
    },
    {
      id: 'reporting-searchverticalcategoryinsightsreport',
      name: _TL_('Category insights'),
      title: _TL_('To view the performance of industry categories on the Bing marketplace. This includes search and syndicated traffic.'),
      fixedRoute: 'reporting/create/searchverticalcategoryinsightsreport',
      route: 'reporting/create/searchverticalcategoryinsightsreport',
      searchAlias: _TL_('Search vertical category insights report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryInsightsReport',
      description: _TL_('Review the performance of industry categories on the Bing marketplace, including Search and syndicated traffic.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultCustomReport,
    },
    {
      id: 'reporting-searchverticalcategoryclicksharereport',
      name: _TL_('Category click coverage'),
      title: _TL_('To view the share of marketplace demand your account(s) have gained in a specific industry category.'),
      fixedRoute: 'reporting/create/searchverticalcategoryclicksharereport',
      route: 'reporting/create/searchverticalcategoryclicksharereport',
      searchAlias: _TL_('Category Click Coverage Report'),
      scope,
      available,
      apiKey: 'SearchVerticalCategoryClickShareReport',
      description: _TL_('Review your account(s) click coverage in a specific industry category.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.DefaultCustomReport,
    },
  ],
};

import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const targetingPlacements = {
  id: 'reporting-category-targeting-placements',
  name: _TL_('Targeting and Placements'),
  scope,
  available,
  children: [
    {
      id: 'reporting-weburlreport',
      name: _TL_('Website URL (publisher)'),
      // Duplicate
      title: _TL_('See performance data for both search and content websites where your ads appear.'),
      fixedRoute: 'reporting/create/weburlreport',
      route: 'reporting/create/weburlreport',
      searchAlias: _TL_('Website URL (publisher) report'),
      scope,
      available,
      apiKey: 'PublisherUsagePerformanceReport',
      description: _TL_('Identify which website URLs perform well for your campaign or ad group target settings, and decide if you need to exclude low-performing websites.'),
    }, {
      id: 'reporting-audiencesreport',
      name: _TL_('Audiences'),
      title: _TL_('See how the ad groups that are associated with remarketing lists are performing.'),
      fixedRoute: 'reporting/create/audiencesreport',
      route: 'reporting/create/audiencesreport',
      searchAlias: _TL_('Audiences report'),
      scope,
      available,
      apiKey: 'AudiencePerformanceReport',
      description: _TL_('Review performance of your campaigns and ad groups across various Audience segments.'),
    }, {
      id: 'reporting-ageandgenderreport',
      name: _TL_('Age and gender'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by gender and age group.'),
      fixedRoute: 'reporting/create/ageandgenderreport',
      route: 'reporting/create/ageandgenderreport',
      searchAlias: _TL_('Age and gender report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'AgeGenderAudienceReport',
      description: _TL_('Review the performance of your campaigns and ad groups across different age groups and genders.'),
    }, {
      id: 'reporting-genrereport',
      name: _TL_('Genre'),
      // Duplicate
      title: _TL_('Genre.'),
      fixedRoute: 'reporting/create/dealgenrereport',
      route: 'reporting/create/dealgenrereport',
      searchAlias: _TL_('Genre report'),
      scope: accountLevelScopes,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'GenrePerformanceReport',
      description: _TL_('Evaluate the performance of the genres targeted in your Premium Streaming campaign to identify which have the best ad results.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        permissions.dynamic.AIMCampaign && permissions.dynamic.XandrVideoCampaigns && permissions.dynamic.GenreTargets,
    }, {
      id: 'reporting-professionaldemographicsreport',
      name: _TL_('Professional demographics'),
      title: _TL_('See total impressions and clicks for each campaign or ad group, organized by company, industry, and job function.'),
      fixedRoute: 'reporting/create/professionaldemographicsreport',
      route: 'reporting/create/professionaldemographicsreport',
      searchAlias: _TL_('Professional demographics report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'ProfessionalDemographicsAudienceReport',
      description: _TL_('Evaluate how your campaigns and ad groups resonate with different companies, industries, and job functions.'),
      checkPermission: /* istanbul ignore next */  permissions =>
        !!permissions.dynamic.LinkedInTargeting || !!permissions.dynamic.AIMCampaign,
    }, {
      id: 'reporting-userlocationreport',
      name: _TL_('User location'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'reporting/create/userlocationreport',
      route: 'reporting/create/userlocationreport',
      searchAlias: _TL_('User location report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'UserLocationPerformanceReport',
      description: _TL_('Identify the locations your traffic is coming from, and the locations they’re searching for.'),
    }, {
      id: 'reporting-geographicreport',
      name: _TL_('Geographic location'),
      // Duplicate
      title: _TL_('See total impressions and clicks for each ad group, organized by the locations where your ads were shown.'),
      fixedRoute: 'reporting/create/geographicreport',
      route: 'reporting/create/geographicreport',
      searchAlias: _TL_('Geographic report'),
      scope,
      available,
      apiKey: 'GeographicPerformanceReport',
      description: _TL_('Identify the locations your traffic is coming from.'),
    },
  ],
};

import { billingPayments } from './billing-payments';
import { settings } from './settings';
import { accountAccess } from './account-access';

import {
  account,
  billing,
  insertionOrder,
  payment,
  rebill,
  xandrBankAssociation,
} from './billing-payments-standalone';
import {
  advancedSettings,
} from './settings-standalone';
import { accountMap } from './account-map-standalone';
import { accountIdentityVerification } from './account-identity-verification-standalone';
import { accountsBulkUpdate } from './accounts-bulk-update-standalone';
import { mandateAgreementRequired } from './mandate-agreement-required-standalone';
import { UKFCAVerification } from './uk-fca-verification-standalone';
import { channelPartner } from './channel-partner-standalone';
import { billingGroupCreation } from './billing-group-creation-standalone';
import { fraudAppeal } from './fraud-appeal-standalone';
import { accountIdentityVerificationAppeal } from './account-identity-verification-appeal-standalone';
import { tradeScreeningTransformation } from './trade-screening-transformation-standalone';
import { unaccessible } from './unaccessible-standalone';
import { welcomeStandalone } from './welcome-standalone';
import { XandrAccountSettings } from './xandr-account-settings-standalone';
import { XandrCustomerOnboarding } from './xandr-customer-onboarding-standalone';
import { customerAccess } from './customer-access-standalone';
import { activateAPContact } from './activate-ap-contact-standalone';
import { signupPaymentSetup } from './signup-payment-setup-standalone';

export const clientCenterPages = [
  billingPayments,
  settings,
  accountAccess,
];

export const clientCenterStandalonePages = [
  account,
  accountsBulkUpdate,
  billing,
  insertionOrder,
  payment,
  rebill,
  advancedSettings,
  accountMap,
  accountIdentityVerification,
  mandateAgreementRequired,
  UKFCAVerification,
  channelPartner,
  billingGroupCreation,
  fraudAppeal,
  accountIdentityVerificationAppeal,
  tradeScreeningTransformation,
  unaccessible,
  welcomeStandalone,
  XandrAccountSettings,
  xandrBankAssociation,
  XandrCustomerOnboarding,
  customerAccess,
  activateAPContact,
  signupPaymentSetup,
];

import { available, scope } from './common';

export const hotel = {
  id: 'reporting-category-hotel',
  name: _TL_('Hotel'),
  title: _TL_('Hotel'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    (permissions.dynamic.HotelCampaign && !permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'reporting-hoteldimensionreport',
      name: _TL_('Hotel dimension'),
      title: _TL_('Hotel dimension'),
      fixedRoute: 'reporting/create/hoteldimensionreport',
      route: 'reporting/create/hoteldimensionreport',
      searchAlias: _TL_('Hotel dimension report'),
      scope,
      available,
      apiKey: 'HotelDimensionPerformanceReport',
      description: _TL_('To figure out which of your hotels are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reporting-hotelgroupreport',
      name: _TL_('Hotel group'),
      title: _TL_('Hotel group'),
      fixedRoute: 'reporting/create/hotelgroupperformancereport',
      route: 'reporting/create/hotelgroupperformancereport',
      searchAlias: _TL_('Hotel group report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelGroupPerformanceReport',
      description: _TL_('To figure out which of your hotels are triggering ads and getting most clicks and optimize the ones not performing so well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reporting-matchingreport',
      name: _TL_('Matching'),
      title: _TL_('Matching'),
      fixedRoute: 'reporting/create/matchingreport',
      route: 'reporting/create/matchingreport',
      searchAlias: _TL_('Matching report'),
      scope,
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIMatchingReport',
      description: _TL_('To see issues which may be preventing your hotels from serving. '),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
    {
      id: 'reporting-pricefeedstatusreport',
      name: _TL_('Price feed status'),
      title: _TL_('Price feed status'),
      fixedRoute: 'reporting/create/pricefeedstatusreport',
      route: 'reporting/create/pricefeedstatusreport',
      searchAlias: _TL_('Price feed status report'),
      scope,
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIPriceFeedStatusReport',
      description: _TL_('To track the status of your pricing updates (transactions).'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.HotelCampaign,
    },
  ],
};

export const property = {
  id: 'reporting-category-property',
  name: _TL_('Ad Type: Property ads'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
  children: [
    {
      id: 'reporting-propertydimensionreport',
      name: _TL_('Property dimension'),
      title: _TL_('Property dimension'),
      fixedRoute: 'reporting/create/propertydimensionreport',
      route: 'reporting/create/propertydimensionreport',
      searchAlias: _TL_('Property dimension report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelDimensionPerformanceReport',
      description: _TL_('Identify which properties generate the most ad clicks so you can improve the ones that are not performing well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reporting-propertygroupreport',
      name: _TL_('Property group'),
      title: _TL_('Property group'),
      fixedRoute: 'reporting/create/propertygroupperformancereport',
      route: 'reporting/create/propertygroupperformancereport',
      searchAlias: _TL_('Property group report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'HotelGroupPerformanceReport',
      description: _TL_('Identify which property groups generate the most ad clicks so you can improve the ones that are not performing well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reporting-matchingreport',
      name: _TL_('Matching'),
      title: _TL_('Matching'),
      fixedRoute: 'reporting/create/matchingreport',
      route: 'reporting/create/matchingreport',
      searchAlias: _TL_('Matching report'),
      scope,
      apexViewName: 'reports-v4_create',
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIMatchingReport',
      description: _TL_('Review possible issues that can prevent your hotel ads from serving.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
    {
      id: 'reporting-pricefeedstatusreport',
      name: _TL_('Price feed status'),
      title: _TL_('Price feed status'),
      fixedRoute: 'reporting/create/pricefeedstatusreport',
      route: 'reporting/create/pricefeedstatusreport',
      searchAlias: _TL_('Price feed status report'),
      scope,
      apexViewName: 'reports-v4_create',
      scopeFilterLevel: ['Account', 'Campaign'],
      available,
      apiKey: 'UIPriceFeedStatusReport',
      description: _TL_('Track the status of your pricing updates (transactions).'),
      checkPermission: /* istanbul ignore next */ permissions =>
        (permissions.dynamic.HotelCampaign && permissions.dynamic.IsRenameHotelToLodgingEnabled),
    },
  ],
};

import { allScopes, alwaysDisplayed } from '../shared';
import { billingAccountLinkPermissions } from '../client-center/billing-payments';
import { defaultReports } from './default-reports';

export const myCustomReportsPage = {
  id: 'reporting-my-reports',
  name: _TL_('Home'),
  fixedRoute: 'reporting/home',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  accountLinkPermissions: billingAccountLinkPermissions,
  checkPermission: permissions => permissions.dynamic.CustomReports,
};

export const reportTemplatePage = {
  id: 'reporting-template-reports',
  name: _TL_('Templates'),
  route: 'reporting/templates',
  fixedRoute: 'reporting/templates',
  scope: allScopes,
  campaignTypes: alwaysDisplayed,
  checkPermission: permissions => permissions.dynamic.CustomReports,
  children: defaultReports,
};


export const customReportsPages = [
  {
    id: 'reporting',
    name: _TL_('Reports'),
    route: 'reporting',
    fixedRoute: 'reporting',
    scope: allScopes,
    campaignTypes: alwaysDisplayed,
    checkPermission: permissions => permissions.dynamic.CustomReports,
    children: [
      myCustomReportsPage,
      reportTemplatePage,
    ],
  },
];

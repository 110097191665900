import { allScopes, Scope } from '../../shared';

const accountLevelScopes = allScopes.filter(scope => scope !== Scope.Customer);


export const labels = {
  id: 'reporting-labels',
  name: _TL_('Labels'),
  dropdown: 'right',
  scope: allScopes,
  children: [
    {
      id: 'reporting-report-labels-keyword',
      name: _TL_('Labels - Keyword'),
      searchAlias: _TL_('Labels - Keyword report'),
      route: 'reporting/create/labelskeywordreport',
      scope: accountLevelScopes,
      apiKey: 'LabelKeywordReport',
      description: _TL_('Get performance data based on keyword labels to identify patterns of performance associated with those labels.'),
    },
    {
      id: 'reporting-report-labels-ad',
      name: _TL_('Labels - Ad'),
      searchAlias: _TL_('Labels - Ad report'),
      route: 'reporting/create/labelsadreport',
      scope: accountLevelScopes,
      apiKey: 'LabelAdReport',
      description: _TL_('Get performance data based on ad labels to identify patterns of performance associated with those labels.'),
    },
    {
      id: 'reporting-report-labels-ad-group',
      name: _TL_('Labels - Ad group'),
      searchAlias: _TL_('Labels - Ad group report'),
      route: 'reporting/create/labelsadgroupreport',
      scope: accountLevelScopes,
      apiKey: 'LabelAdGroupReport',
      description: _TL_('Get performance data based on ad group labels to identify their patterns of performance associated with those labels.'),
    },
    {
      id: 'reporting-report-labels-campaign',
      name: _TL_('Labels - Campaign'),
      searchAlias: _TL_('Labels - Campaign report'),
      route: 'reporting/create/labelscampaignreport',
      scope: accountLevelScopes,
      apiKey: 'LabelCampaignReport',
      description: _TL_('Get performance data based on campaign to identify their patterns of performance associated with those labels.'),
    },
    {
      id: 'reporting-labels-account',
      name: _TL_('Labels - Account'),
      searchAlias: _TL_('Labels - Account report'),
      route: 'reporting/create/labelsaccountreport',
      scope: [Scope.Customer],
      apiKey: 'LabelAccountReport',
      description: _TL_('Get performance data based on account to identify their patterns of performance associated with those labels.'),
    },
  ],
};

import { Scope } from '../configs/shared';
import {
  brandKitPage,
  displayTemplatePage,
  homePage,
  imagesPage,
  libraryPage,
  performancePage,
  videosPage,
  videoTemplatePage,
} from '../configs/tools/shared-library/ad-studio';

export const adStudio = {
  id: 'asset-library',
  name: _TL_('Ads Studio'),
  description: _TL_('Manage all of your creative assets in one place.'),
  oldUIScope: [Scope.Account],
  scope: [Scope.Account],
  route: 'adstudio',
  children: [
    homePage,
    imagesPage,
    displayTemplatePage,
    videosPage,
    videoTemplatePage,
    performancePage,
    brandKitPage,
    libraryPage,
  ],
  checkPermission: permissions => permissions.dynamic.AdsStudioMVP,
};

import { available, scope } from './common';
import { Scope } from '../../shared';

const accountLevelScopes = scope.filter(_scope => _scope !== Scope.Customer);

export const performance = {
  id: 'reporting-category-performance',
  name: _TL_('Performance'),
  title: _TL_('Performance reports give you a closer look at how your campaigns are performing, including details like CTR and impressions.'),
  scope,
  available,
  children: [
    {
      id: 'reporting-accountreport',
      name: _TL_('Account'),
      title: _TL_('Understand account performance and identify trends.'),
      fixedRoute: 'reporting/create/accountreport',
      route: 'reporting/create/accountreport',
      searchAlias: _TL_('Account report'),
      scope,
      available,
      apiKey: 'AccountPerformanceReport',
      description: _TL_('Get an overview of your account performance.'),
    }, {
      id: 'reporting-campaignreport',
      name: _TL_('Campaign'),
      title: _TL_('See a snapshot of your account, and if there\'s anything to flag.'),
      fixedRoute: 'reporting/create/campaignreport',
      route: 'reporting/create/campaignreport',
      searchAlias: _TL_('Campaign report'),
      scope,
      available,
      apiKey: 'CampaignPerformanceReport',
      description: _TL_('Get an overview of your campaign performance.'),
    }, {
      id: 'reporting-adgroupreport',
      name: _TL_('Ad group'),
      title: _TL_('See if your ad group is meeting your preset goals.'),
      fixedRoute: 'reporting/create/adgroupreport',
      route: 'reporting/create/adgroupreport',
      searchAlias: _TL_('Ad group report'),
      scope,
      available,
      apiKey: 'AdGroupPerformanceReport',
      description: _TL_('Review your performance by ad group.'),
    }, {
      id: 'reporting-adreport',
      name: _TL_('Ad'),
      title: _TL_('See which ads are leading to clicks and conversions.'),
      fixedRoute: 'reporting/create/adreport',
      route: 'reporting/create/adreport',
      searchAlias: _TL_('Ad report'),
      scope,
      available,
      apiKey: 'AdPerformanceReport',
      description: _TL_('Identify which ads lead to clicks and conversions, and which are not performing. Underperforming ads can pull down the quality of your campaigns.'),
    }, {
      id: 'reporting-keywordreport',
      name: _TL_('Keyword'),
      title: _TL_('See which keywords are leading to clicks.'),
      fixedRoute: 'reporting/create/keywordreport',
      route: 'reporting/create/keywordreport',
      searchAlias: _TL_('Keyword report'),
      scope,
      available,
      apiKey: 'KeywordPerformanceReport',
      description: _TL_('Identify the keywords that trigger your ads and get clicks, and those that are underperforming.'),
    }, {
      id: 'reporting-negativekeywordconflictreport',
      name: _TL_('Negative keyword conflicts'),
      title: _TL_('See a set of keyword and negative keyword pairs that are blocking your bids from participating in the auction.'),
      fixedRoute: 'reporting/create/negativekeywordconflictreport',
      route: 'reporting/create/negativekeywordconflictreport',
      searchAlias: _TL_('Negative keyword conflicts report'),
      scope,
      apexViewName: 'reports-v4_create',
      available,
      apiKey: 'NegativeKeywordConflictReport',
      description: _TL_('Identify which keywords and negative keywords are in conflict, and whether this conflict is at the campaign or ad group level. Use this list to determine which negative keywords to delete.'),
    }, {
      id: 'reporting-searchtermreport',
      name: _TL_('Search term'),
      // Duplicate
      title: _TL_('See which search terms are causing your ads to display.'),
      fixedRoute: 'reporting/create/searchtermreport',
      route: 'reporting/create/searchtermreport',
      searchAlias: _TL_('Search term report'),
      scope,
      available,
      apiKey: 'SearchQueryPerformanceReport',
      description: _TL_('Get insight into what your audience is searching for when your ads are shown, so that you can make informed additions, removals, or edits to both your keyword and negative keyword lists.'),
    }, {
      id: 'reporting-msxaccountperformancereport',
      name: _TL_('MSX account performance'),
      title: _TL_('Access and review daily revenue trends.'),
      fixedRoute: 'reporting/create/msxaccountperformancereport',
      route: 'reporting/create/msxaccountperformancereport',
      searchAlias: _TL_('MSX account performance report'),
      scope,
      available,
      apiKey: 'MSXAccountPerformanceReport',
      description: _TL_('Access and review daily revenue trends.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.IsMSXAccountPerformanceReportEnabled,
    }, {
      id: 'reporting-SearchInsightPerformanceReport',
      name: _TL_('Search terms insights'),
      // Duplicate
      title: _TL_('See which search insight are causing your ads to display.'),
      fixedRoute: 'reporting/create/SearchInsightPerformanceReport',
      route: 'reporting/create/SearchInsightPerformanceReport',
      searchAlias: _TL_('Search terms insights report'),
      scope: accountLevelScopes,
      available,
      apiKey: 'SearchInsightPerformanceReport',
      description: _TL_('Gain valuable insights into how your customers search for your business. These insights analyze the specific search terms that triggered your ads during a selected time frame. We’ve grouped the terms into relevant search categories, that provide key performance metrics for each category. Please note that only Performance Max campaigns are supported at this time.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.SearchInsightPerformanceReport,
    }, {
      id: 'reporting-CombinationPerformanceReport',
      name: _TL_('Combination performance'),
      title: _TL_('Identify the most frequently occurring asset combinations and provide a view of your combination performance.'),
      fixedRoute: 'reporting/create/CombinationPerformanceReport',
      route: 'reporting/create/CombinationPerformanceReport',
      searchAlias: _TL_('Combination performance report'),
      scope,
      available,
      apiKey: 'CombinationPerformanceReport',
      description: _TL_('Identify which asset combinations are performing well.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        permissions.dynamic.CombinationReport,
    },
  ],
};

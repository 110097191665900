import { Scope, ScopeV2, CampaignType } from '../shared';

ScopeV2.add('AssetGroup', 'assetGroupId', ScopeV2.Campaign);

export const unifiedAppAdsAssetGroupDetails = {
  id: 'unified-app-ads-asset-details',
  name: _TL_('Asset details'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'unified-app-ads-assets',
      name: _TL_('Assets'),
      scope: [Scope.Campaign],
      scopeV2: [ScopeV2.AssetGroup],
      campaignTypes: [CampaignType.App],
      route: 'unifiedappads/assets',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.UnifiedAppAds,
    },
  ],
};

export const unifiedAppAdsAssetGroups = {
  id: 'unified-appads-asset-group',
  name: _TL_('Asset groups'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'unified-appads-asset-group',
      name: _TL_('Asset groups'),
      route: 'unifiedappadsassetgroups',
      searchable: false,
      checkPermission: permissions => permissions.dynamic.UnifiedAppAds,
    },
  ],
};


export const unifiedAppAdsAssetGroupsCreation = {
  id: 'unified-appads-asset-group-create',
  name: _TL_('Create asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.Campaign],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'unified-appads-asset-group-create',
      name: _TL_('Create asset group'),
      route: 'unifiedappadsassetgroups/create',
      checkPermission: permissions => permissions.dynamic.UnifiedAppAds,
    },
  ],
};

export const unifiedAppAdsAssetGroupsEdit = {
  id: 'unified-appads-asset-group-edit',
  name: _TL_('Edit asset group'),
  scope: [Scope.Campaign],
  scopeV2: [ScopeV2.AssetGroup],
  campaignTypes: [CampaignType.App],
  children: [
    {
      id: 'unified-appads-asset-group-edit',
      name: _TL_('Edit asset group'),
      route: 'unifiedappadsassetgroups/edit',
      checkPermission: permissions => permissions.dynamic.UnifiedAppAds,
    },
  ],
};

import { available, scope } from './common';

export const dynamicSearch = {
  id: 'reporting-category-dynamicsearch',
  name: _TL_('Ad Type: Dynamic search ads'),
  title: _TL_('Dynamic search ads reports allow you to see a holistic view of your campaigns’ dynamic ad target, category, and search term performance.'),
  scope,
  available,
  checkPermission: /* istanbul ignore next */ permissions =>
    !!permissions.dynamic.DynamicSearchAdsV1,
  children: [
    {
      id: 'reporting-dsadautotargetreport',
      name: _TL_('Dynamic Search ads auto target'),
      title: _TL_('See the clicks, impressions, and other performance metrics of each of your dynamic ad targets.'),
      fixedRoute: 'reporting/create/dsadautotargetreport',
      route: 'reporting/create/dsadautotargetreport',
      searchAlias: _TL_('Dynamic search ad auto target'),
      scope,
      available,
      apiKey: 'DSAAutoTargetPerformanceReport',
      description: _TL_('Get a detailed analysis of clicks, impressions, and other performance metrics for each dynamic ad target, helping you identify opportunities for bid adjustments to optimize your ad campaigns.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    }, {
      id: 'reporting-dsadcategoryreport',
      name: _TL_('Dynamic Search ad category'),
      title: _TL_('See the clicks, impressions, and other performance generated from your website.'),
      fixedRoute: 'reporting/create/dsadcategoryreport',
      route: 'reporting/create/dsadcategoryreport',
      searchAlias: _TL_('Dynamic search ad category report'),
      scope,
      available,
      apiKey: 'DSACategoryPerformanceReport',
      description: _TL_('Evaluate the performance of your existing category targets or identify new categories to target.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    }, {
      id: 'reporting-dsadsearchtermreport',
      name: _TL_('Dynamic Search ad search term'),
      title: _TL_('See search terms that triggered your dynamic search ads and their corresponding headlines, final URLs, and associated performance metrics.'),
      fixedRoute: 'reporting/create/dsadsearchtermreport',
      route: 'reporting/create/dsadsearchtermreport',
      searchAlias: _TL_('Dynamic search ad search term report'),
      scope,
      available,
      apiKey: 'DSASearchQueryPerformanceReport',
      description: _TL_('Evaluate how your ads perform against search terms, identify negative keywords, and find the right keywords that create conversions.'),
      checkPermission: /* istanbul ignore next */ permissions =>
        !!permissions.dynamic.DynamicSearchAdsV1,
    },
  ],
};
